import WOW from 'wow.js';

const wowJsInit = (() => {
  const init = () => {
    $('.animate__animated').each((index, elem) => {
      const $elem = $(elem);
      
      const animationOffset = $(window).width() < 1024 ? 100 : (($(window).height() / 2) * 0.2) * -1;

      $elem.attr("data-wow-offset", animationOffset);
    })

    const wow = new WOW({
      boxClass: 'animate__animated',
      animateClass: 'animated',
    }).init();
  }

  return {
    init,
  };
})();

export default wowJsInit;