/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const videoFeatures = (() => {
  const init = () => {
    const $videoItems = $('.video-features .video-holder');

    $videoItems.each((index, item) => {
      const $videoItem = $(item);
      const videoPlayer = $videoItem.find('.video-box video')[0];
      const $playButton = $videoItem.find('.play-btn');
      const $videoPlaceholderBox = $videoItem.find('.video-placeholder');
      const $videoPlaceholder = $videoItem.find('.video-placeholder video');

      $playButton.on('click', () => {
        $videoPlaceholderBox.hide();
        $videoPlaceholder[0].pause();
        $videoPlaceholder[0].currentTime = 0;
        $videoItem.addClass('is-playing');
        $playButton.hide();
        $videoItem.find('.video-box').css({ display: 'block' });
        $(videoPlayer).removeAttr('tabindex');
        videoPlayer.play();
      });

      $playButton.on('mouseenter', () => {
        $videoPlaceholder[0].play();
      });

      $playButton.on('mouseleave', () => {
        $videoPlaceholder[0].pause();
        $videoPlaceholder[0].currentTime = 0;
      });
    });
  };

  return {
    init,
  };
})();

export default videoFeatures;
