const header = (() => {
  function headerToggle() {
    $('.header-nav-toggle').on('click', (e) => {
      const dropdown = $(e.target).next();
      let delay = 0;

      if ($('.header').hasClass('js-search-active')) {
        delay = 300;
        $('.header-search-toggle').click();
      }

      setTimeout(() => {
        if (e.target.getAttribute('aria-expanded') === 'false') {
          e.target.setAttribute('aria-expanded', 'true');
          dropdown.attr('aria-hidden', 'false');
          $('body, html').addClass('js-fixed');
          $('.header').addClass('js-nav-active');
        } else {
          e.target.setAttribute('aria-expanded', 'false');
          dropdown.attr('aria-hidden', 'true');
          $('body, html').removeClass('js-fixed');
          $('.header').removeClass('js-nav-active');
        }
      }, delay);
    });

    $('.header-nav__main > li').on('mouseleave', (e) => {
      if ($(window).width() > 1024) {
        const dropdown = $('.header-nav__subnav');
        dropdown.attr('aria-hidden', 'true');
        $('.header-nav__main > li').removeClass('js-hovered');
      }
    });

    $('.header-nav__main > li').on('mouseover', (e) => {
      e.stopPropagation();

      if ($(window).width() > 1024) {
        const dropdown = $(e.target).children('.header-nav__subnav');
        dropdown.attr('aria-hidden', 'false');
        $(e.target).addClass('js-hovered');
      }
    });

    $('.header-nav__main > li > a').on('focus', (e) => {
      if ($(window).width() > 1024) {
        $('.header-nav__subnav').attr('aria-hidden', 'true');
        const dropdown = $(e.target).parent().children('.header-nav__subnav');
        dropdown.attr('aria-hidden', 'false');
      }
    });

    $('.header-nav__main > li:last .header-nav__subnav li:last > a').on(
      'keydown',
      (e) => {
        if (e.keyCode === 9 && !e.shiftKey && $(window).width() >= 1024) {
          $(e.target).parent().parent().attr('aria-hidden', 'true');
        }
      },
    );

    $('.secondary-nav-toggle').on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      const dropdown = $(e.target).next();

      if (e.target.getAttribute('aria-expanded') === 'false') {
        e.target.setAttribute('aria-expanded', 'true');
        dropdown.attr('aria-hidden', 'false');
        dropdown.slideDown(300);
      } else {
        e.target.setAttribute('aria-expanded', 'false');
        dropdown.attr('aria-hidden', 'true');
        dropdown.slideUp(300);
      }
    });

    $('.header-nav__bttn').on('click', (e) => {
      const dropdown = $(e.target).next();

      if (e.target.getAttribute('aria-expanded') === 'false') {
        e.target.setAttribute('aria-expanded', 'true');
        dropdown.attr('aria-hidden', 'false');
        $(e.target).parent().addClass('js-open');
        dropdown.slideDown(300);
      } else {
        e.target.setAttribute('aria-expanded', 'false');
        dropdown.attr('aria-hidden', 'true');
        $(e.target).parent().removeClass('js-open');
        dropdown.slideUp(300);
      }
    });

    const bttn = $('.header-nav-toggle');

    $(document).on('keydown', (e) => {
      if (e.key === 'Escape') {
        if ($(window).width() > 1024) {
          const dropdown = $('.header-nav__subnav');
          dropdown.attr('aria-hidden', 'true');
          $('.header-nav__main > li').removeClass('js-hovered');
        }

        if (bttn.attr('aria-expanded') === 'true') {
          bttn.trigger('click');
        }
      }
    });

    const tabOne = $('.header-nav-toggle');
    const tabTwo = $('.header-ancillary__primary:last > a');

    tabOne.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey) {
        if ($(e.target).attr('aria-expanded') === 'true') {
          e.preventDefault();
          tabTwo.focus();
        }
      }
    });

    tabTwo.on('keydown', (e) => {
      if ($(window).width() < 1025 && e.keyCode === 9 && !e.shiftKey) {
        e.preventDefault();
        tabOne.focus();
      }
    });

    $(window).on('load resize', (e) => {
      if ($(window).width() < 1025 && !$('.header-nav').attr('aria-hidden')) {
        if ($('.header-nav-toggle').attr('aria-expanded') === 'true') {
          $('.header-nav').attr('aria-hidden', 'false');
        } else {
          $('.header-nav').attr('aria-hidden', 'true');
        }
      }

      if ($(window).width() >= 1025 && $('.header-nav').attr('aria-hidden')) {
        $('.header-nav').removeAttr('aria-hidden');
      }
    });
  }

  function searchToggle() {
    $('.header-search-toggle').on('click', (e) => {
      const dropdown = $(e.target).prev();
      let delay = 0;

      if ($('.header').hasClass('js-nav-active')) {
        delay = 300;
        $('.header-nav-toggle').click();
      }

      setTimeout(() => {
        if (e.target.getAttribute('aria-expanded') === 'false') {
          e.target.setAttribute('aria-expanded', 'true');
          dropdown.attr('aria-hidden', 'false');
          $('body').addClass('js-fixed');
          $('.header').addClass('js-search-active');
          $('.header-search__form > input[type="search"]').focus();
        } else {
          e.target.setAttribute('aria-expanded', 'false');
          dropdown.attr('aria-hidden', 'true');
          $('body').removeClass('js-fixed');
          $('.header').removeClass('js-search-active');
        }
      }, delay);
    });
  }

  function searchTabLoop() {
    const tabOne = $('#search');
    const tabTwo = $('.header-search-toggle');

    tabOne.on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey) {
        e.preventDefault();
        tabTwo.focus();
      }
    });

    tabTwo.on('keydown', (e) => {
      if (e.keyCode === 9 && !e.shiftKey) {
        if ($(e.target).attr('aria-expanded') === 'true') {
          e.preventDefault();
          tabOne.focus();
        }
      }
    });
  }

  const init = () => {
    headerToggle();
    searchToggle();
    searchTabLoop();
  };

  return {
    init,
  };
})();

export default header;