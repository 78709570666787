import '@babel/polyfill';
import '@accessible360/accessible-slick';
import 'object-fit-images';

import accordion from './object/accordion';
import inlineVideo from './object/video';
import column from './object/column';
//import instagramLoader from './object/instafeed';
import instagram from './object/instagram';
import twitterLoader from './object/twitter';
import facultyTable from './object/faculty-table';
import expertsTable from './object/experts-table';
import catalogProgram from './object/catalog-program';
import carousel from './object/carousel';
import programFinder from './object/program-finder';
import calendar from './object/calendar';
import catalog from './object/catalog';
import stickyCta from './object/sticky-cta';
import tableScroll from './object/scroll-table';
import sectionNavigation from './object/sectionNavigation';
import header from './object/header';
import designHelpers from './object/design-helpers';
import storyExplorer from './object/story-explorer';
import storyExplorerVideo from './object/story-explorer-video';
import easyLightbox from './object/easy-lightbox';
import easyLightboxVideoPlayer from './object/easy-lightbox-video-player';
import videoFeatures from './object/video-features';
import heroHomepage from './object/hero-homepage';
import wowJsInit from './object/wowJsInit';
import academicsListAnimate from './object/academicsListAnimate';
import randImg from './object/rand-img';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

$(() => {
  designHelpers.init();
  accordion.init();
  inlineVideo.init();
  //instagramLoader.init();
  instagram.init();
  twitterLoader.init();
  facultyTable.init();
  expertsTable.init();
  calendar.init();
  catalogProgram.init();
  carousel.init();
  programFinder.init();
  catalog.init();
  tableScroll.init();
  stickyCta.init();
  sectionNavigation.init();
  header.init();
  storyExplorer.init();
  storyExplorerVideo.init();
  easyLightbox.init();
  easyLightboxVideoPlayer.init();
  videoFeatures.init();
  heroHomepage.init();
  academicsListAnimate.init();
  wowJsInit.init();
  randImg.init();
  objectFitImages();

  // Run after instagram and others have loaded
  $(window).on('load resize', () => {
    setTimeout(() => {
      column.init();
    }, 100);
  });
});
