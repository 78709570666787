const expertsTable = (() => {
  require('datatables.net-dt')();
  require('datatables.net-buttons')();

  const directoryInit = () => {
    const $tableHolder = $('#experts-table');
    const ajaxPath = $tableHolder.attr('data-url');

    function renderTable(sortedData) {
      const table = $tableHolder.DataTable({
        data: sortedData,
        processing: true,
        pagingType: 'simple_numbers',
        sPaginationType: 'ellipses',
        deferLoading: 57,
        ordering: false,
        autoWidth: false,

        language: {
          search: '<span class="search-title form__label">Search Within Table</span>',
          searchPlaceholder: 'Type to filter table by keyword',
          infoFiltered: '',
          infoEmpty: '',
          info: `<strong>_START_-_END_</strong>
                <span>of</span>
                <strong>_TOTAL_</strong>`,
          sLengthMenu: `<div class="table-length-wrap">
              <select name="table-length" class="table-length form__control">
                <option value="10">Show <span>10</span></option>
                <option value="20">Show <span>20</span></option>
                <option value="30">Show <span>30</span></option>
                <option value="40">Show <span>40</span></option>
                <option value="50">Show <span>50</span></option>
                <option value="-1">All</option>
              </select>
              <span>per page</span>
            </div>`,
        },
        columns: [
          {
            data: 'image',
          },
          {
            data: 'displayName',
          },
          {
            data: 'areasOfExpertise',
            className: 'show-filter-list'
          },
          {
            data: 'email',
          },
        ],
        columnDefs: [
          {
            targets: 0,
            data: 'image',
            render(data, type, row) {
              if (row.url) {
                return `<div><a href="${row.url || '#'}"><img src='${data}'/></a></div>`;
              }
              return `<div><img src='${data}'/></div>`;
            },
          },
          {
            targets: 1,
            data: "displayName",
            render(data, type, row){
               if (row.url) {
                return `<div class="row-section">
                  <span class="row-label">Name & Title</span>
                  <div class="row-content">
                  <a class="link-name cta cta--link" href="${row.url}">${row.displayName}</a>
                  <span class="title">${row.title}</span>
                  </div>
                  </div>`;
              }
              return `<div class="row-section">
                  <span class="row-label">Name & Title</span>
                  <div class="row-content">
                  <span class="link-name" >${row.displayName}</span>
                  <span class="title">${row.title}</span>
                  </div>
                  </div>`;
            },
          },
          {
            targets: 2,
            data: 'areasOfExpertise',
            render(data) {
              return `<div class="row-section"><span class="row-label">Areas of Expertise</span><span class="areasOfExpertise">${data}</span></div>`;
            },
          },
          
          {
            targets: 3,
            data: 'email',
            render(data, type, row) {
              return data && row ? `<div class="row-section"><span class="row-label">Email</span><a class="email" href="${row.email}">${data}</a></div>` : '';
            },
          },
          {
            targets: '_all',
            orderable: false,
          },
        ],
        dom:
          '<"heading-table"B<"heading-table-filter"<"mobile-filter-drop"<"toolbar">rf>>><"wrapper-table"<"table-holder"t>><"footer-table"p<"table-info"li>>',
        buttons: [
          {
            text: 'Experts Directory Filters',
            className: 'filter-btn-drop',
            action: () => {
              const $filterHolder = $('.heading-table .heading-table-filter');
              const $filterToggle = $('.heading-table .filter-btn-drop');

              $filterToggle.toggleClass('expanded');
              $filterHolder
                .stop()
                .slideToggle(300)
                .attr(
                  'aria-hidden',
                  $filterHolder.attr('aria-hidden') === 'false'
                    ? 'true'
                    : 'false',
                );
            },
          },
        ],
        keys: true,
        responsive: true,
        initComplete() {
          this.api()
            .columns('.show-filter-list')
            .every(function(i) {
              const column = this;
              const info = this.page.info();
              const toolbar = $('.toolbar');
              toolbar.addClass('form')
              const holder = document.createElement('div');
              const label = document.createElement('label');
              const searchHolder = $('#experts-table_filter');
              const select = $(
                '<select class="form__control filter"><option value="">All</option></select>',
              );

              $(label).text(this.header().innerText);

              $(label)
                .addClass('form__label')
                .attr('for', `input-${i}`)
                .appendTo(holder);
              select.attr('id', `input-${i}`).appendTo(holder);
              $(holder)
                .addClass('filter-box')
                .appendTo(toolbar);
              searchHolder.appendTo(toolbar);

              select.on('change', function() {
                const val = $.fn.dataTable.util.escapeRegex($(this).val());
                
                column.search(val ? `${val}` : val, true, true, false).draw();
              });

              column
                .data()
                .unique()
                .sort()
                .each(function(d, j) {
                  if(Array.isArray(d)){
                    d.forEach(x => {
                      select.append(`<option value="${x}">${x}</option>`);
                  });
                  } else {
                    select.append(`<option value="${d}">${d}</option>`);
                  }
                });
            });
        },
      });

      const renderBtnClear = container => {
        const btnClear = document.createElement('button');
        const iconClear = document.createElement('i');
        const filterHolder = $(container);
        $(btnClear)
          .addClass('clear-table')
          .html('<span>Reset filter</span>');
        $(iconClear)
          .addClass('icon icon-refresh')
          .attr('aria-hidden', 'true');
        $(iconClear).appendTo(btnClear);
        $(btnClear).insertAfter(filterHolder);
      };

      const resetFilter = () => {
        $('.clear-table').on('click', function(e) {
          e.preventDefault();
          $('.filter').prop('selectedIndex', 0);
          table
            .search('')
            .columns()
            .search('')
            .draw();
        });
      };

      renderBtnClear('.dataTables_filter');
      resetFilter();
    }

    $.getJSON(ajaxPath, json => {
      const arr = json;

      // Sort array alphabetically before rendering table
      arr.data.sort(function sortResults(a, b) {
        const nameA = a.lastName.toUpperCase();
        const nameB = b.lastName.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      renderTable(arr.data);
      $('#experts-table').removeAttr('role');
      setTimeout(() => {
        $('#experts-table_wrapper').find('.paginate_button').removeAttr('aria-current');
        $('#experts-table_wrapper').find('.paginate_button.current').attr('aria-current', 'true');
      }, 80);
    });
  };

  const init = () => {
    if ($('#experts-table').length) {
      directoryInit();

      $('#experts-table').on('page.dt', function(){
        setTimeout(() => {
          $('#experts-table_wrapper').find('.paginate_button').removeAttr('aria-current');
          $('#experts-table_wrapper').find('.paginate_button.current').attr('aria-current', 'true');
        }, 80);
      });
    }
  };

  return {
    init,
  };
})();

export default expertsTable;
