// this helper responsible to detect existence some sections on page or
// resolve some cases that can't be resolved by css only

const designHelpers = (() => {
  const init = () => {
    const $body = $('body');

    // footer-sock section existence
    if (!$('.footer-sock').length) {
      $body.addClass('no-footer-sock');
    }


    // detect safari browser
    var ua = navigator.userAgent.toLowerCase(); 
    if (ua.indexOf('safari') != -1) { 
      if (ua.indexOf('chrome') > -1) {
        // chrome
        return;
      } else {
        // Safari
        $body.addClass('is-safari');
      }
    }
  };

  return {
    init,
  };
})();

export default designHelpers;
