const heroHomepage = (() => {
  const init = () => {
    const IS_PLAYING_CLASSNAME = 'is-playing';
    const CAN_PLAY_CLASSNAME = 'can-play';
    const $heroSection = $('.homepage-hero');
    const videoPlayer = $heroSection.find('.video-holder video')[0];
    const $playButton = $heroSection.find('.play-button');

    if (!videoPlayer) {
      return
    }

    videoPlayer.oncanplay = () => {
      videoPlayer.play();
      $heroSection.addClass(`${IS_PLAYING_CLASSNAME} ${CAN_PLAY_CLASSNAME}`);
      $playButton.find('.visually-hidden').text('pause video');
    }

    $playButton.on('click', () => {
      if ($heroSection.hasClass(IS_PLAYING_CLASSNAME)) {
        videoPlayer.pause();
        $heroSection.removeClass(IS_PLAYING_CLASSNAME);
        $playButton.find('.visually-hidden').text('play video');
      } else {
        videoPlayer.play();
        $heroSection.addClass(IS_PLAYING_CLASSNAME);
        $playButton.find('.visually-hidden').text('pause video');
      }
    });
  }

  return {
    init,
  };
})();

export default heroHomepage;
