const storyExplorer = (()=>{
  const STORY_DISPLAY_ITEM_SELECTOR_ATTR = 'data-story-display-index';
  const ACTIVE_STORY_ITEM_CLASSNAME = 'active';
  const SECTION_EDGE_CLASSNAME = 'is-on-edge';
  const Y_REVERSE_CLASSNAME = 'y-reverse';


  const init = () => {
    const $window = $(window);
    const $storySections = $('.story-explorer--map, .story-explorer--icons');

    $storySections.each((index, item) => {
      const $section = $(item);
      const $storyExplorerBox = $section.find('.story-explorer__box');
      const $storyDisplayBox = $storyExplorerBox.find('.story-explorer__display');
      const $storyItems = $storyExplorerBox.find('.story-explorer__list-item');
      const $storyDotHolder = $storyExplorerBox.find('.story-explorer__dot-holder');
      const IS_MAP_MODE = $section.hasClass('story-explorer--map');

      const setLabelsState = (x, y, $label) => {
        $label
          .css({
            left: x + 'px',
            '--location-top-offset': y + 'px' ,
            '--max-width-error-shift': x + 'px',
          });
      };

      const setDotState = (x, y, $storyDisplayItem) => {
        const $storyImage = $storyDisplayItem.find('.story-explorer__display-map img');
        const $storyDot = $storyDisplayItem.find('.location');
        
        const cX = ($storyImage.outerWidth() * (x / 100));
        const posX = cX + (($storyDisplayItem.outerWidth() - $storyImage.outerWidth()) / 2);
        let cY = ($storyImage.outerHeight() * (y / 100));
        const isAfterHalfHeight = cY < $storyImage.outerHeight() / 2;

        let posY;

        if (isAfterHalfHeight) {
          $storyDotHolder.addClass(Y_REVERSE_CLASSNAME);
        } else {
          $storyDotHolder.removeClass(Y_REVERSE_CLASSNAME);
        }

        posY = Math.abs(cY - ($storyDisplayItem.outerHeight() / 2) + (($storyDisplayItem.outerHeight() - $storyImage.outerHeight()) / 2));

        $storyDotHolder.css({
          width: posX + 'px',
          height: posY + 'px',
        });

        setLabelsState(posX, isAfterHalfHeight ? (posY * -1) : posY, $storyDot);
      };

      const setActiveItem = function() {
        const $story = $(this);
        const $storyDisplayItem = $section.find(`[${STORY_DISPLAY_ITEM_SELECTOR_ATTR}="${$story.attr('data-story-list-item-index')}"]`);

        const STORY_OFFSET_TOP = $story.offset().top;
        const STORY_HEIGHT = $story.outerHeight();
        const STORY_DISPLAY_BOX_HEIGHT = $storyDisplayBox.outerHeight();
        const SCROLL_POSITION  = $window.scrollTop();

        if (SCROLL_POSITION <= $storyExplorerBox.offset().top && $window.width() < 700) {
          $storyItems.removeClass(ACTIVE_STORY_ITEM_CLASSNAME);

          $storyItems
            .first()
            .addClass(ACTIVE_STORY_ITEM_CLASSNAME);

          $section
            .find(`[${STORY_DISPLAY_ITEM_SELECTOR_ATTR}="0"]`)
            .addClass(ACTIVE_STORY_ITEM_CLASSNAME);

          if (IS_MAP_MODE) {
            setDotState(
              $storyItems.first().attr('data-axis-x'),
              $storyItems.first().attr('data-axis-y'),
              $storyDisplayItem,
            );
          }
          
          return;
        }

        let ACTIVE_ITEM_CONDITION;

        if ($window.width() < 700) {
          ACTIVE_ITEM_CONDITION =
            (SCROLL_POSITION + STORY_DISPLAY_BOX_HEIGHT >= STORY_OFFSET_TOP) && 
            (SCROLL_POSITION + STORY_DISPLAY_BOX_HEIGHT < STORY_OFFSET_TOP + STORY_HEIGHT);
        } else {
          ACTIVE_ITEM_CONDITION = 
            SCROLL_POSITION + ($window.height()/2) > STORY_OFFSET_TOP &&
            SCROLL_POSITION + ($window.height()/2) < STORY_OFFSET_TOP + STORY_HEIGHT;
        }

        if (ACTIVE_ITEM_CONDITION) {
          $story.addClass(ACTIVE_STORY_ITEM_CLASSNAME);
          $storyDisplayItem.addClass(ACTIVE_STORY_ITEM_CLASSNAME);
          if (IS_MAP_MODE) {
            setDotState(
              $story.attr('data-axis-x'),
              $story.attr('data-axis-y'),
              $storyDisplayItem,
            );
          }
        } else {
          if ((SCROLL_POSITION + 
            ($window.width() < 700 ? STORY_DISPLAY_BOX_HEIGHT : STORY_DISPLAY_BOX_HEIGHT/2) >= 
            STORY_OFFSET_TOP + STORY_HEIGHT) && $story.is(':last-child')) 
          {
            $story.addClass(ACTIVE_STORY_ITEM_CLASSNAME);
            $storyDisplayItem.addClass(ACTIVE_STORY_ITEM_CLASSNAME);
            return;
          }

          $story.removeClass(ACTIVE_STORY_ITEM_CLASSNAME);
          $storyDisplayItem.removeClass(ACTIVE_STORY_ITEM_CLASSNAME);
        }

        if ($storyItems.last().hasClass(ACTIVE_STORY_ITEM_CLASSNAME) && $window.width() < 700) {
          $storyExplorerBox.addClass(SECTION_EDGE_CLASSNAME);
        } else {
          $storyExplorerBox.removeClass(SECTION_EDGE_CLASSNAME);
        }
      }

      const addListeners = () => {
        $storyItems.each((index, item) => {
          const $story = $(item);

          $window.on('scroll resize load', setActiveItem.bind($story));

          $window.on('resize load', () => {
            if ($window.width() < 700 && $storyDisplayBox.outerHeight() * 1.5 > $window.height()) {
              $storyExplorerBox.addClass('static-display');
            } else {
              $storyExplorerBox.removeClass('static-display');
            }

            $storyExplorerBox.css(
              {
                '--display-top-offset': $storyExplorerBox.outerHeight() - $storyDisplayBox.outerHeight() - $storyItems.last().outerHeight() + 'px',
              }
            );
          });
        });
      }

      addListeners();
    });
  }

  return {
    init,
  }
})();

export default storyExplorer;