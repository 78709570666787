/**
 * Static CTAs interactions and accessiblity
 * @param {*} $
 * @returns {init}
 */

const stickyCta = (() => {
  function slickInit() {
    const bttn = $('.sticky-ctas__bttn');

    bttn.on('click', (e) => {
      const dropdown = $(e.target).next();

      if (e.target.getAttribute('aria-expanded') === 'false') {
        e.target.setAttribute('aria-expanded', 'true');
        dropdown.attr('aria-hidden', 'false');
        dropdown.slideDown(300);
      } else {
        e.target.setAttribute('aria-expanded', 'false');
        dropdown.attr('aria-hidden', 'true');
        dropdown.slideUp(300);
      }
    });

    const tabOne = $('.sticky-ctas__bttn');
    const tabTwo = $('.sticky-ctas__item:last > a');
    
    tabOne.on('keydown', e => {
      if (e.keyCode === 9 && e.shiftKey) {
        if ($(e.target).attr('aria-expanded') === 'true') {
          e.preventDefault();
          tabTwo.focus();
        }
      }
    });
    
    tabTwo.on('keydown', e => {
      if (e.keyCode === 9 && !e.shiftKey) {
        e.preventDefault();
        tabOne.focus();
      }
    });
  }

  const init = () => {
    if (!$('.sticky-ctas').length) {
      return;
    }

    slickInit();
  };

  return {
    init,
  };
})();

export default stickyCta;
