/**
 * Random Image interactions and accessiblity
 * @param {*} $
 * @returns {init}
 */

const randImg = (() => {
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const init = () => {
    if (!$('.rand-img').length) {
      return;
    }

    $('.rand-img').each((i, elem) => {
      const $str = $(elem).attr('data-src');
      const $arr = $str.split(', ');
      const $int = getRandomInt(0, $arr.length - 1);
      $(elem).attr('src', $arr[$int]);

      setTimeout(() => {
        $(elem).removeAttr('style');
      }, 100);
    });
  };

  return {
    init,
  };
})();

export default randImg;
