/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carousel = (() => {

  const mobileNavPosition = ($carousel) => {
    const $window = $(window);
    const SHOW_CONTROLS_CLASSNAME = 'show-controls';
    const UNSTICK_CONTROLS_CLASSNAME = 'unstick-controls';
    let scrollPosition = 0;
    let crouselTopPosition = $carousel.offset().top;
    let showControls = false;
    let unstickControls = false;

    $window.on('scroll resize load', () => {
      scrollPosition = $window.scrollTop();
      crouselTopPosition = $carousel.offset().top;
      showControls = scrollPosition + ($window.height() * 0.6) >= crouselTopPosition;
      unstickControls = (scrollPosition + $window.height()) >= (crouselTopPosition + $carousel.outerHeight());

      if (showControls) {
        $carousel.addClass(SHOW_CONTROLS_CLASSNAME);
      } else {
        $carousel.removeClass(SHOW_CONTROLS_CLASSNAME);
      }

      if (unstickControls) {
        $carousel.addClass(UNSTICK_CONTROLS_CLASSNAME);
      } else {
        $carousel.removeClass(UNSTICK_CONTROLS_CLASSNAME);
      }
    })
  }

  const makeCarousel = ($carousel) => {
    const $window = $(window);
    const SLIDE_TRANSITION_TIME = 0;
    const $dotsHolder = $carousel.siblings('.slick-dots__holder');

    $carousel.slick({
      autoplay:true,
      autoplaySpeed:5000,
      dots: true,
      infinite: true,
      speed: SLIDE_TRANSITION_TIME,
      fade: true,
      slidesToShow: 1,
      appendDots: $dotsHolder,
      adaptiveHeight: false,
    });

    $carousel.on('beforeChange', function() {
      const $videos = $carousel.find('video');
      
      if (Boolean($videos.length)) {
        $videos.each((index, video) => {
          video.pause();
        })
      }
    });

    $window.on('load resize load', () => {
      $dotsHolder.height($dotsHolder.find('.slick-dots').outerHeight());
    });

    mobileNavPosition($carousel.closest('.carousel__wrapper'));
  }

  const init = () => {
    $('.carousel .carousel-box').each((index, item) => {
      makeCarousel($(item));
    })
  };

  return {
    init,
  };
})();

export default carousel;
