const storyExplorerVideo = (()=>{
 
  const init = () => {
    const $window = $(window);
    const $videoStorySections = $('.story-explorer--video');

    $videoStorySections.each((index, item) => {
      const $section = $(item);
      const $videoBox =  $section.find('.story-explorer__display .video-box');
      const videoPlayer = $videoBox.find('video')[0];
      const $playBtn = $videoBox.find('.play-video-btn');

      $window.on('scroll', () => {
        if ($window.scrollTop() > $section.offset().top && !$videoBox.hasClass('paused-manually')) {
          $videoBox.addClass('is-playing');
          videoPlayer.play();
        }

      // commented code under pause video when video-holder unsticks
      // $window.on('scroll', () => {
      //   if (
      //     ($window.scrollTop() > $section.offset().top) && 
      //     ($window.scrollTop() + $window.height() < $section.offset().top + $section.outerHeight())
      //   ) {
      //     $videoBox.addClass('is-playing');
      //     videoPlayer.play();
      //   } else {
      //     $videoBox.removeClass('is-playing');
      //     videoPlayer.pause();
      //   }
      // });
      });

      $playBtn.on('click', () => {
        if($videoBox.hasClass('paused-manually')) {
          $videoBox.removeClass('paused-manually');
          videoPlayer.play();
          $playBtn.find('.visually-hidden').text('Pause video');
          $videoBox.addClass('is-playing');
        } else {
          videoPlayer.pause();
          $playBtn.find('.visually-hidden').text('Play video');
          $videoBox.removeClass('is-playing');
          $videoBox.addClass('paused-manually');
        }
      });
    });
  }

  return {
    init,
  }
})();

export default storyExplorerVideo;