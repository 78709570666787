const $ = require('jquery');

const sectionNavigation = (() => {
  const NAVIGATION_OPENED_CLASSNAME = 'nav-expandded';
  const BODY_FIXED_CLASSNAMES = 'js-fixed';
  const ANIMATION_SPEED = 300;
  const $window = $(window);
  const $bodyHtml = $('body, html');
  const $breadcrumbsSection = $('.breadcrumbs');
  const $breadcrumbsListWrapper = $('.breadcrumbs__list-wrapper');
  const $openNavBtn = $('.breadcrumbs__opener');
  const $closeNavBtn = $('.breadcrumbs__close');
  const $navBox = $('.breadcrumbs__box');
  const $firstLink = $navBox.find('.breadcrumbs__item-link').first();
  const $lastLink = $navBox.find('.breadcrumbs__sub-link').last();
  const $breadcrumbsSubList = $navBox.find('.breadcrumbs__item.current .breadcrumbs__sub-list');
  const $submenuOpener = $('.breadcrumbs__item.current button.breadcrumbs__item-link');
  
  const toggleNavDesktop = () => {
    if ($window.width() >= 1025) {
      if (!$breadcrumbsSection.hasClass(NAVIGATION_OPENED_CLASSNAME)) {
        $submenuOpener.attr('aria-expanded', true);
        $breadcrumbsSubList
          .attr('aria-hidden', false)
          .show();

        setTimeout(() => {
          $breadcrumbsSection.addClass(NAVIGATION_OPENED_CLASSNAME);
        }, 0);
      } else {
        $breadcrumbsSection.removeClass(NAVIGATION_OPENED_CLASSNAME);
        $submenuOpener.attr('aria-expanded', false);
        $breadcrumbsSubList
          .attr('aria-hidden', true);

        setTimeout(() => {
          $breadcrumbsSubList.hide();
        }, ANIMATION_SPEED)
      }
    }
  }

  const openNavMobile = () => {
    if ($window.width() < 1025) {
      $openNavBtn.attr('aria-expanded', true);
      $navBox.attr('aria-hidden', false);
      $navBox.fadeIn(ANIMATION_SPEED, () => {
        setTimeout(() => {
          setSectionNavOffset();
          $bodyHtml.addClass(BODY_FIXED_CLASSNAMES);
          $breadcrumbsSection.addClass(NAVIGATION_OPENED_CLASSNAME);
          $firstLink.focus();
        }, ANIMATION_SPEED)
      });
    }
  }

  const closeNavMobile = () => {
    if ($window.width() < 1025) {
      $bodyHtml.removeClass(BODY_FIXED_CLASSNAMES);
      $breadcrumbsSection.removeClass(NAVIGATION_OPENED_CLASSNAME);
      $openNavBtn.attr('aria-expanded', false);
      $navBox.attr('aria-hidden', true);
      
      setTimeout(() => {
        $navBox.fadeOut(ANIMATION_SPEED, () => {
          $openNavBtn.focus();
        });
      }, 400);
    }
  }

  const initKeyboardNav = () => {
    $closeNavBtn.keydown((e) => {
      if ($window.width() < 1025) {
        if (e.keyCode === 9 && !e.shiftKey) {
          e.preventDefault();
          $firstLink.focus();
        }
      }
    });

    $firstLink.keydown((e) => {
      if ($window.width() < 1025) {
        if(e.keyCode === 9 && e.shiftKey) {
          e.preventDefault();
          $closeNavBtn.focus();
        }
      }
    });

    $lastLink.keydown((e) => {
      if ($window.width() >= 1025) {
        if(e.keyCode === 9 && !e.shiftKey) {
          e.preventDefault();
          $submenuOpener.focus();
        }
      }
    });
    
    $submenuOpener.keydown((e) => {
      if ($window.width() >= 1025) {
        if(e.keyCode === 9 && e.shiftKey && $breadcrumbsSection.hasClass(NAVIGATION_OPENED_CLASSNAME)) {
          e.preventDefault();
          $lastLink.focus();
        }
      }
    });
  }

  const setSectionNavOffset = () => {
    $navBox.css({'--nav-offset': `${$breadcrumbsListWrapper.outerHeight()}px`});
  }

  const init = () => {
    $submenuOpener.click(toggleNavDesktop);
    $openNavBtn.click(openNavMobile);
    $closeNavBtn.click(closeNavMobile);
    initKeyboardNav();

    if ($window.width() < 1025) {
      $submenuOpener.attr('aria-expanded', true);
      $breadcrumbsSubList.attr('aria-hidden', false);
    } else {
      $navBox.attr('aria-hidden', false);
      $submenuOpener.attr('aria-expanded', false);
      $breadcrumbsSubList.attr('aria-hidden', true);
    }
  }

  return {
    init,
  }
})();

export default sectionNavigation;