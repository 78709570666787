const academicsListAnimate = (() => {
  const init = () => {    
    $('.learn-online__section').each((index, elem) => {
      let delay = 0;
      const delayIncrement = 0.3;
  
      $(elem).find('.learn-online__list-item').each((index, item) => {
        $(item).css('--animation-delay', delay + 's');
        delay += delayIncrement;
      });
    });
  }

  return {
    init,
  };
})();

export default academicsListAnimate;