const easyLightbox = (()=>{
  let $lastLightboxOpener;
  const PREVENT_SCROLL_CLASSNAME = 'js-fixed';
  const ANIMATIONS_SPEED = 300;

  const initKeyboardNav = ($focusableItems) => {
    $focusableItems.first().on('keydown', (e) => {
      if (e.keyCode === 9 && e.shiftKey) {
        e.preventDefault();
        $focusableItems.last().focus();
      }
    });

    $focusableItems.last().on('keydown', (e) => {
      if (e.keyCode === 9 && !e.shiftKey) {
        e.preventDefault();
        $focusableItems.first().focus();
      }
    });
  }

  const openLightbox = (opener) => {
    const $body = $('body');
    const $bodyHtml = $('body, html');
    const $opener = $(opener);
    $lastLightboxOpener = $opener;

    const $lightboxContent = $(`[data-lightbox-content="${$opener.attr('data-lightbox')}"]`).clone();

    const labelledbyIdVal = $lightboxContent.find('[data-id]').attr('data-id');
    
    $lightboxContent.find('[data-id]').attr('id', labelledbyIdVal);

    const $template = $(`
      <div class="easy-lightbox" role="dialog" aria-labelledby="${labelledbyIdVal}" style="display: none">
        <div class="easy-lightbox__overlay"></div>
        <div class="easy-lightbox__wrapper">
          <button class="easy-lightbox__close">
            <span class="visually-hidden">close modal</span>
          </button>
          <div class="easy-lightbox__content">
      
          </div>
        </div>
      </div>
    `);

    $lightboxContent.addClass('content-visible');

    $template
      .find('.easy-lightbox__content')
      .append($lightboxContent);
      
    $body.append($template);
    
    $bodyHtml.addClass(PREVENT_SCROLL_CLASSNAME);

    $template.fadeIn(ANIMATIONS_SPEED);

    const $focusable = $template.find('a, button, input, textarea');

    $focusable.first().focus();

    initKeyboardNav($focusable);
  }

  const closeLightbox = () => {
    $('.easy-lightbox').fadeOut(ANIMATIONS_SPEED, () => {
      $('.easy-lightbox').remove();
      $('body, html').removeClass(PREVENT_SCROLL_CLASSNAME);
    });

    $lastLightboxOpener.focus();
  }

  const addListeners = () => {
    $(document).on('click', '.easy-lightbox__close, .easy-lightbox__overlay', closeLightbox);

    $(document).on('keydown', (e) => {
      if(e.keyCode === 27) {
        closeLightbox();
      }
    });
    
    $('[data-lightbox]').on('click', (e) => {
      openLightbox(e.currentTarget);
    })
  }

  const init = () => {
    addListeners();
  }

  return {
    init,
  }
})();

export default easyLightbox;