const easyLightboxVideoPlayer = (()=>{
  const IS_PLAYING_CLASSNAME = 'is-playing';

  const addListeners = () => {
    $(document).on('click', '.story-lightbox.video-lightbox .video-box .play-btn', (e) => {

      $(e.currentTarget)
        .closest('.video-box')
        .addClass(IS_PLAYING_CLASSNAME)
        .find('video').removeAttr('tabindex')[0].play();
    });
  }

  const init = () => {
    addListeners();
  }

  return {
    init,
  }
})();

export default easyLightboxVideoPlayer;